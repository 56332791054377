import React, { Component } from 'react';
import Container from '@material-ui/core/Container';
import { CardContent } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';

// Components
import Menu from '../../components/menu/Menu';
import DataTable from '../../components/datatable/DataTable';
import ModalCG from '../../components/modal_cg/ModalCG';
import Modal from '@material-ui/core/Modal';
import UserForm from '../user_form/UserForm';

// Services
import { getUsers, handlerDisabledUser } from '../../services/firebase';

// Constants
const defaultStyle = {
  cellStyle: {
    textAlign : 'center',
    openModal: false
  },
}

class Users extends Component {

  constructor(props) {
    super(props);

    this.state = {
      users : [],
      modalForm: false,
      dataToEdit: null
    };
  }
  
  componentDidMount(){
    this.callTableData()
  }

  callTableData = ()=> {
    getUsers()
    .then( async result => {
      let users = result;
      this.setState({users});
    })
    .catch(error => {
      console.log(error)
    })
  }

  onRowClick = (event, rowData) => {
    console.log('Row event');    
  }

  editUserForm = (event, rowData) => {
    this.setState({ modalForm: true, dataToEdit: event })
  }

  cleanModalData = () => {
    this.setState({ modalForm: false, dataToEdit: null })
  }

  closeModalAndUpdate = () => {
    this.setState({ modalForm: false, dataToEdit: null })
    this.callTableData()
  }

  goToForm = ()=> {
    this.setState({ modalForm: true })
  }

  handlerDisabled = (row) => {
    handlerDisabledUser(row.uid, {disabled: !row.disabled})
    .then(response => {
      if (response) {
        let newUserList = this.state.users

        newUserList = newUserList.map( value => {
          if (value.uid === row.uid) {
            value.disabled = !row.disabled
          }
          return value
        })

        this.setState({ users: newUserList })
      }

    })
    .catch(error => {
      console.log(error)
    })
  }

  continueDelete = ()=> {
    this.handlerModalTable()
    this.handlerDisabled(this.state.rowData)
  }

  handlerModalTable = ()=> {
    this.setState({ openModal: !this.state.openModal })
  }

  handleStatus = (rowData)=>{
    this.handlerModalTable();
    this.setState({ rowData })
  }

  render() {
    const columns = [
      { 
        title: 'Customer ID', 
        field: 'customer_id',
        ...defaultStyle
      },
      { 
        title: 'Email', 
        field: 'email',
        ...defaultStyle
      },
      { 
        title: 'First Name', 
        field: 'firstName',
        ...defaultStyle
      },
      { 
        title: 'Last Name', 
        field: 'lastName', 
        ...defaultStyle
      },
      { 
        title: 'Postal Code', 
        field: 'postalCode', 
        ...defaultStyle
      },
      { 
        title: 'Address', 
        field: 'address', 
        ...defaultStyle
      },
      { 
        title: 'Creation Date', 
        field: 'metadata.creationTime', 
        ...defaultStyle
      },
      { 
        title: 'last Sign In Date', 
        field: 'metadata.lastSignInTime', 
        ...defaultStyle
      },
      { 
        title: 'Subscription', 
        field: 'subscription_id',
        render: rowData => {
          return(
            rowData.subscription_id ? 'Active' : 'Free'
          )
        },
        ...defaultStyle
      },
      { 
        title: 'Disabled', 
        field: 'disabled',
        render: rowData => {
          return(
            <Switch
              checked={rowData.disabled}
              onChange={()=> this.handleStatus(rowData) }
              color="primary"
              inputProps={{ 'aria-label': 'primary checkbox' }}
          />
          )
        },
        ...defaultStyle
      }
    ]
    return (
      <div>
        <Grid item xs={12} display="flex" justifyContent="center" > 
          <Menu />
          <Box display="flex" justifyContent="center">
            <Grid xs={12} sm={10} md={10}>

              <Grid container spacing={2} style={{paddingTop: '32px'}}>
                <Grid item >
                  <Typography component="h1" variant="h5">
                    Users
                  </Typography>
                </Grid>
                <Grid item >
                  <Button
                    variant="contained"
                    color="primary"
                    // className={classes.button}
                    startIcon={<AddCircleIcon />}
                    onClick={this.goToForm}
                  > 
                    Add
                  </Button>
                </Grid>
              </Grid>
              <Container maxWidth={false} style={{paddingTop: '32px'}}>
                <Grid container spacing={3}>
                  <CardContent style={{width: "100%"}}>
                    <DataTable 
                      title="Users List" 
                      data={[...this.state.users]} 
                      columns={columns} 
                      grouping={true}
                      selection={false}
                      exportButton={true}
                      toolbar={true}
                      showSelectAllCheckbox={false}
                      actionsColumnIndex={-1}
                      onRowClick={this.onRowClick}
                      editTada={this.editUserForm}
                    />
                  </CardContent>
                </Grid>
              </Container>

            </Grid>
          </Box>
        </Grid>

        <ModalCG
            openModal={this.state.openModal}
            type="continue"
            title="Are you sure you want to continue?"
            btcCancel={{
                    text:"Cancel",
                    action: this.handlerModalTable
                }}
            btnAccept={{
                    text: "Continue",
                    action: this.continueDelete
                }}
        />

        <Modal
          open={ this.state.modalForm }
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <UserForm closeModalForm={ this.cleanModalData } closeModalAndUpdate={ this.closeModalAndUpdate } dataToEdit={this.state.dataToEdit}/>
        </Modal>
      </div>
    )
  }
}

export default Users;